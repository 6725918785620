import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StepperService {
    stepper$: BehaviorSubject<string> = new BehaviorSubject<string>('step1');
    constructor() {}

    get stepper(): Observable<string> {
        return this.stepper$.asObservable();
    }

    updateStep(value: string): void {
        return this.stepper$.next(value);
    }
}
