import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CallSession } from '../models/call-session';
import { Builder } from 'builder-pattern';

@Injectable({
    providedIn: 'root',
})
export class CallSessionService {
    constructor(private http: HttpClient) {}

    public getJaakrecogToken(): Observable<CallSession> {
        const data = {};

        return this.http
            .post(`${environment.api}/api/v1/call/session/`, data)
            .pipe(
                map((response: any) =>
                    Builder(CallSession)
                        .assistantUrl(response.assistant_url)
                        .clientUrl(response.client_url)
                        .eventId(response.eventId)
                        .message(response.message)
                        .status(response.status)
                        .build(),
                ),
            );
    }
}
