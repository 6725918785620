import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { SettingsModalComponent } from '../../modals/settings-modal/settings-modal.component';
import { LoginEmailModalComponent } from '../../modals/login-email-modal/login-email-modal.component';
import { LoginIPModalComponent } from '../../modals/login-ip-modal/login-ip-modal.component';
import { LoginPhoneModalComponent } from '../../modals/login-phone-modal/login-phone-modal.component';
import { ConfirmPhoneModalComponent } from '../../modals/confirm-phone-modal/confirm-phone-modal.component';
import { OnBordingModalComponent } from '../../modals/on-bording-modal/on-bording-modal.component';
import { VideoCaptureModalComponent } from '../../modals/video-capture-modal/video-capture-modal.component';
import { AlertModalComponent } from '../../modals/alert-modal/alert-modal.component';
import { LoadingModalComponent } from '../../modals/loading-modal/loading-modal.component';
import { VideoRetryModalComponent } from '../../modals/video-retry-modal/video-retry-modal.component';
import { OnBoardingIdCardComponent } from '../../modals/on-boarding-id-card/on-boarding-id-card.component';
import { CardIdCaptureModalComponent } from '../../modals/card-id-capture-modal/card-id-capture-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OnBoardingIdCardBackComponent } from '../../modals/on-boarding-id-card-back/on-boarding-id-card-back.component';
import { HttpClientModule } from '@angular/common/http';
import { ValidationModalComponent } from '../../modals/validation-modal/validation-modal.component';
import { ValidateDataModalComponent } from '../../modals/validate-data-modal/validate-data-modal.component';
import { CaptureVideoModalComponent } from '../../modals/capture-video-modal/capture-video-modal.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { StepOneComponent } from 'src/app/steps/step-one/step-one.component';
import { StepTwoComponent } from '../../steps/step-two/step-two.component';
import { StepThreeComponent } from 'src/app/steps/step-three/step-three.component';
import { StepFourComponent } from '../../steps/step-four/step-four.component';
import { StepFiveComponent } from '../../steps/step-five/step-five.component';
import { StepSixComponent } from '../../steps/step-six/step-six.component';
import { RequestLocationModalComponent } from '../../modals/request-location-modal/request-location-modal.component';
import { StepBlackListComponent } from '../../steps/step-black-list/step-black-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ResultsComponent } from '../../steps/results/results.component';
import { VideoCallComponent } from '../../modals/video-call/video-call.component';
import { StepperComponent } from '../../components/stepper/stepper.component';
@NgModule({
    declarations: [
        AlertModalComponent,
        ButtonComponent,
        CaptureVideoModalComponent,
        CardIdCaptureModalComponent,
        ConfirmPhoneModalComponent,
        HeaderComponent,
        LoadingComponent,
        LoadingModalComponent,
        LoginEmailModalComponent,
        LoginIPModalComponent,
        LoginPhoneModalComponent,
        OnBoardingIdCardBackComponent,
        OnBoardingIdCardComponent,
        OnBordingModalComponent,
        RequestLocationModalComponent,
        SettingsModalComponent,
        StepBlackListComponent,
        StepFiveComponent,
        StepFourComponent,
        StepOneComponent,
        StepSixComponent,
        StepperComponent,
        StepThreeComponent,
        StepTwoComponent,
        ValidateDataModalComponent,
        ValidationModalComponent,
        VideoCallComponent,
        VideoCaptureModalComponent,
        VideoRetryModalComponent,
        ResultsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        HttpClientModule,
        ImageCropperModule,
    ],
    exports: [
        AlertModalComponent,
        ButtonComponent,
        CaptureVideoModalComponent,
        CardIdCaptureModalComponent,
        ConfirmPhoneModalComponent,
        HeaderComponent,
        LoadingComponent,
        LoadingModalComponent,
        LoginEmailModalComponent,
        LoginIPModalComponent,
        LoginPhoneModalComponent,
        MatExpansionModule,
        OnBoardingIdCardBackComponent,
        OnBoardingIdCardComponent,
        OnBordingModalComponent,
        RequestLocationModalComponent,
        SettingsModalComponent,
        StepBlackListComponent,
        StepFiveComponent,
        StepFourComponent,
        StepOneComponent,
        StepSixComponent,
        StepperComponent,
        StepThreeComponent,
        StepTwoComponent,
        ValidateDataModalComponent,
        ValidationModalComponent,
        VideoCallComponent,
        VideoCaptureModalComponent,
        VideoRetryModalComponent,
        ResultsComponent,
    ],
})
export class CommonsModule {}
