import { Component, Input, OnInit } from '@angular/core';
import { Strings } from '../../classes/messages';
import { StorageService } from '../../services/storage.service';
import { ModalController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';
import { ModalService } from '../../services/modal.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-step-six',
    templateUrl: './step-six.component.html',
    styleUrls: ['./step-six.component.scss'],
})
export class StepSixComponent implements OnInit {
    @Input() identityResponse: { score: number; approve: boolean };
    data: any;
    constructor(
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private storageSrv: StorageService,
        private stepperSrv: StepperService,
    ) {}

    async ngOnInit(): Promise<void> {
        const image1 = await this.storageSrv.getIneFrontImage();
        const image2 = await this.storageSrv.getBestFrame();
        this.data = {
            eventId: await this.storageSrv.getEventId(),
            image1: `data:image/jpg;base64,${image1}`,
            image2: `data:image/jpg;base64,${image2}`,
        };

        console.log(this.data.image1);
    }

    retry(): void {
        this.stepperSrv.updateStep('step4');
    }

    async next(): Promise<void> {
        this.stepperSrv.updateStep('stepList');
    }
}
