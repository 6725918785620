import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Document } from 'src/app/models/document';
import {
    IneFrontData,
    GeoElectoralData,
    IneBackData,
} from '../../models/document';
import { ToolsService } from '../../services/tools.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-validate-data-modal',
    templateUrl: './validate-data-modal.component.html',
    styleUrls: ['./validate-data-modal.component.scss'],
})
export class ValidateDataModalComponent implements OnInit {
    dataForm: FormGroup;
    @Input() data: Document;

    constructor(
        private fb: FormBuilder,
        private toolsSrv: ToolsService,
        private modalCtrl: ModalController,
    ) {}

    ngOnInit() {
        console.log(this.data);
        this.initForm();
    }

    initForm(): void {
        const userData: IneFrontData = this.data.documentData.ineFrontData;
        const geoData: GeoElectoralData = this.data.documentData.ineFrontData
            .geoElectoralData;
        const backData: IneBackData = this.data.documentData.ineBackData;
        this.dataForm = this.fb.group({
            name: [userData.name, [Validators.required]],
            lastname: [userData.surname, [Validators.required]],
            surname: [userData.motherSurname, [Validators.required]],
            birthdate: [userData.birthDate, [Validators.required]],
            gender: [userData.gender, [Validators.required]],
            street: [userData.address.street, [Validators.required]],
            suburb: [userData.address.neighborhood, [Validators.required]],
            zipCode: [userData.address.zipCode, [Validators.required]],
            city: [userData.address.city, [Validators.required]],
            state: [userData.address.state, [Validators.required]],
            electoralKey: [userData.electorKey, [Validators.required]],
            curp: [userData.curp, [Validators.required]],
            stateCode: [geoData.stateCode, [Validators.required]],
            cityCode: [geoData.cityCode, [Validators.required]],
            sectionCode: [geoData.sectionCode, [Validators.required]],
            localityCode: [geoData.localityCode, [Validators.required]],
            registerYear: [userData.registerYear, [Validators.required]],
            emissionYear: [userData.emissionYear, [Validators.required]],
            validUntil: [userData.validUntil, [Validators.required]],
            cardId: [
                backData.numIdentificationCredential,
                [Validators.required],
            ],
            key: [backData.identificationOCR, [Validators.required]],
        });
    }

    async continue(): Promise<void> {
        await this.toolsSrv.goRouteCameraPermission();
        await this.modalCtrl.dismiss();
    }
}
