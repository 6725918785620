import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';
@Component({
  selector: 'app-on-bording-modal',
  templateUrl: './on-bording-modal.component.html',
  styleUrls: ['./on-bording-modal.component.scss'],
})
export class OnBordingModalComponent implements OnInit {

  @ViewChild('onBording')  slides: IonSlides;
  slideOpts = {
    allowTouchMove: false,
  };
  constructor(
    private modalCtrl: ModalController,
    private toolsSrv: ToolsService
  ) { }

  ngOnInit() {}

  async swipeNext(): Promise<void> {
    await this.slides.slideNext();
  }

  async swipeBack(): Promise<void> {
    await this.slides.slidePrev();
  }

  async continue(): Promise<void> {
    const index = await this.slides.getActiveIndex();
    if (index < 2) {
      this.swipeNext();
    }else {
      await this.modalCtrl.dismiss();
      this.toolsSrv.goRouteCameraPermission();
    }
  }

}
