import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Renderer2,
    HostListener,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Strings } from 'src/app/classes/messages';
import { Alert } from 'src/app/interfaces/alert';
import { VideoCaptureOptions } from '../../interfaces/capture-options';
import { ModalService } from '../../services/modal.service';
import { LivenessService } from '../../services/liveness.service';
import { OneToOneService } from 'src/app/services/one-to-one.service';
import { StorageService } from '../../services/storage.service';
import { NotificationService } from '../../services/notification.service';
import { StepperService } from '../../services/stepper.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToolsService } from '../../services/tools.service';
declare var faceapi: any;
declare var MediaRecorder;
@Component({
    selector: 'app-capture-video-modal',
    templateUrl: './capture-video-modal.component.html',
    styleUrls: ['./capture-video-modal.component.scss'],
})
export class CaptureVideoModalComponent implements OnInit, AfterViewInit {
    @ViewChild('video') video: ElementRef;
    @Input() stream: MediaStream;
    @Input() options: VideoCaptureOptions;
    stepper = new BehaviorSubject<string>('step1');
    showAnimation = true;
    showLoading = true;
    faceExists = new BehaviorSubject<boolean>(false);
    message: string;
    trackingFn: any;
    mediaRecorder: any;
    recordedBlobs: any;
    stepperObservable;

    @HostListener('window:resize', ['$event'])
    private onResize() {
        this.resizeEllipsis();
    }
    constructor(
        private authSrv: AuthenticationService,
        private livenessSrv: LivenessService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private notificationSrv: NotificationService,
        private oneToOneSrv: OneToOneService,
        private renderer: Renderer2,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
        private toolsSrv: ToolsService,
    ) {}

    async ngOnInit(): Promise<void> {
        console.log(this.options);
        this.resizeEllipsis();
        this.initStepper();
        await this.initComponent();
    }

    async ngAfterViewInit(): Promise<void> {}

    initStepper(): void {
        this.stepperObservable = this.stepper
            .asObservable()
            .subscribe(async (value) => {
                this.resizeEllipsis();
                if (value === 'step2') {
                    this.step2();
                }
                if (value === 'step3') {
                    this.step3();
                }
            });
    }

    async initComponent(): Promise<void> {
        this.showLoading = true;
        if (this.options?.trackFace) {
            await this.setupFaceApi();
            this.initVideoStreaming();
            this.showLoading = false;
        } else {
            this.initVideoStreaming();
            this.showLoading = false;
        }
    }

    initVideoStreaming(): void {
        setTimeout(() => {
            this.video.nativeElement.srcObject = new MediaStream(this.stream);
            this.video.nativeElement.play();
        }, 100);
    }
    async setupFaceApi(): Promise<void> {
        const path = '../../../assets/weights';
        await faceapi.nets.ssdMobilenetv1.loadFromUri(path);
        await faceapi.nets.tinyFaceDetector.loadFromUri(path);
        await faceapi.nets.faceRecognitionNet.loadFromUri(path);
        await faceapi.nets.faceLandmark68Net.loadFromUri(path);
    }

    async start(): Promise<void> {
        this.stepper.next('step2');
    }

    async step2(): Promise<void> {
        this.message = 'Analisis facial';
        if (this.options?.trackFace) {
            this.faceTracking(this.video.nativeElement);
            const trackFace = this.faceExists.subscribe((value) => {
                if (value) {
                    document.getElementsByTagName('canvas')[0].style.position =
                        'absolute';
                    this.message = 'Analisis facial';
                    setTimeout(() => {
                        clearInterval(this.trackingFn);
                        trackFace.unsubscribe();
                        document.getElementsByTagName(
                            'canvas',
                        )[0].style.display = 'none';
                        this.goToStep3();
                    }, 3000);
                } else {
                    this.message = 'No se ha detectado tu rostro';
                }
            });
        } else {
            setTimeout(() => {
                this.goToStep3();
            }, 3000);
        }
    }

    goToStep2(): void {
        this.stepper.next('step2');
    }

    step3(): void {
        this.stepperObservable.unsubscribe();
        this.message = 'Grabando';
        this.startRecording();
        setTimeout(async () => {
            this.mediaRecorder.pause();
            this.mediaRecorder.stop();
            const blob = this.getBlob();
            await this.modalSrv.openModalLoading();
            const video = await this.getBase64(blob);
            await this.verifyVideo(video);
        }, 5000);
    }

    goToStep3(): void {
        this.stepper.next('step3');
    }

    async closeModal(): Promise<void> {
        this.stopStreaming();
        await this.modalCtrl.dismiss();
        return;
    }

    stopStreaming(): void {
        const stream = this.video.nativeElement.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            track.stop();
        });
        this.video.nativeElement.srcObject = null;
    }

    faceTracking(video): void {
        const canvas = faceapi.createCanvasFromMedia(video);
        document.getElementById('video-container').append(canvas);
        document.getElementsByTagName('canvas')[0].style.transform =
            'rotateY(180deg)';
        const displaySize = {
            width: video.clientWidth,
            height: video.clientHeight,
        };
        faceapi.matchDimensions(canvas, displaySize);
        this.trackingFn = setInterval(async () => {
            const detections = await faceapi
                .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks();
            if (detections) {
                this.faceExists.next(true);
                const resizeDetections = faceapi.resizeResults(
                    detections,
                    displaySize,
                );
                canvas
                    .getContext('2d')
                    .clearRect(0, 0, canvas.width, canvas.height);
                faceapi.draw.drawFaceLandmarks(canvas, resizeDetections);
            } else {
                this.faceExists.next(false);
            }
        }, 100);
    }

    startRecording(): void {
        let options = { mimeType: 'video/webm; codecs=opus,vp8' };
        if (MediaRecorder.isTypeSupported) {
            options = { mimeType: 'video/webm;codecs=vp9' };
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.error(`${options.mimeType} is not Supported`);
                options = { mimeType: 'video/webm;codecs=vp8' };
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.error(`${options.mimeType} is not Supported`);
                    options = { mimeType: 'video/webm' };
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        console.error(`${options.mimeType} is not Supported`);
                        options = { mimeType: '' };
                    }
                }
            }
        } else {
            options = { mimeType: '' };
        }
        this.recordedBlobs = [];
        try {
            this.mediaRecorder = new MediaRecorder(this.stream, options);
        } catch (e) {
            this.notificationSrv
                .showToastDanger('Navegador no compatible. Intenta otra vez')
                .then();
            this.closeModal().then();
            console.log(e);
        }
        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                this.recordedBlobs.push(event.data);
            }
        };
        this.mediaRecorder.start(100);
    }

    getBlob(): Blob {
        const blob = new Blob(this.recordedBlobs, { type: 'video/webm' });
        console.log(URL.createObjectURL(blob));
        return blob;
    }

    getBase64(file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve((reader.result as string).split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    }

    async verifyVideo(video: string): Promise<any> {
        try {
            const eventId = await this.storageSrv.getEventId();
            const res = await this.livenessSrv
                .verifyBestFrame([video], eventId)
                .pipe(first())
                .toPromise();
            console.log(res);
            this.stopStreaming();
            await this.modalCtrl.dismiss(null, null, 'video-capture');
            await this.modalCtrl.dismiss(null, null, 'loading');
            if (res.status && res.evaluation >= res.threshold) {
                const alert: Alert = this.createSuccessAlert(res.bestFrame);
                await this.modalSrv.openModalAlert(alert);
            } else {
                await this.modalSrv.openModalVideoError();
            }
        } catch (e) {
            this.stopStreaming();
            await this.modalCtrl.dismiss(null, null, 'video-capture');
            await this.modalCtrl.dismiss(null, null, 'loading');
            if (e.error.type === 'unauthorized') {
                await this.authSrv.logout();
                setTimeout(() => {
                    this.toolsSrv.goRouteLoginPage();
                }, 150);
            }
            const alert: Alert = this.createErrorAlert(
                e.error.message,
                e.error.type,
            );
            await this.modalSrv.openModalAlert(alert);
        }
    }

    createSuccessAlert(bestFrameImage: string): Alert {
        return {
            type: 'success',
            message: Strings.successLiveness,
            buttonType: 'continue',
            buttonFunction: async () => {
                try {
                    await this.modalCtrl.dismiss();
                    await this.storageSrv.setBestFrame(bestFrameImage);
                    this.stepperSrv.updateStep('step5');
                } catch (e) {
                    console.log(e);
                    const alert: Alert = this.createErrorAlert();
                    await this.modalSrv.openModalAlert(alert);
                }
            },
        };
    }

    createErrorAlert(errMsg?: string, errType?: string): Alert {
        const buttonType = errType === 'unauthorized' ? null : 'try';
        return {
            type: errType === 'unauthorized' ? 'error' : 'server',
            message: errMsg ? errMsg : Strings.errorServer,
            buttonType,
            buttonFunction: () => {
                this.modalCtrl.dismiss();
            },
        };
    }

    resizeEllipsis(): void {
        if (window.innerWidth < 768) {
            setTimeout(() => {
                const ellipsis = document.getElementById('ellipse');
                const svg = document.getElementsByTagName('svg')[0];
                console.log(svg.width);
                const svgWidth = svg.clientWidth;
                const svgHeight = svg.clientHeight;
                const magicNumberX = 0.6956521739130435;
                const magicNumberY = 0.9352708058124174;
                console.log(svgHeight, svgWidth);
                this.renderer.setAttribute(
                    ellipsis,
                    'cx',
                    (svgWidth / 2).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'cy',
                    (svgHeight / 2).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'rx',
                    ((svgWidth / 2) * magicNumberX).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'ry',
                    ((svgHeight / 2) * magicNumberY).toString(),
                );
                ellipsis.style.display = 'block';
            }, 50);
        } else if (window.innerHeight < 840 && window.innerWidth > 900) {
            setTimeout(() => {
                const ellipsis = document.getElementById('ellipse');
                const svg = document.getElementsByTagName('svg')[0];
                console.log(svg.width);
                const svgWidth = svg.clientWidth;
                console.log(svgWidth);
                const svgHeight = svg.clientHeight;
                const magicNumberX = 0.6956521739130435;
                const magicNumberY = 0.9352708058124174;
                console.log(svgHeight, svgWidth);
                this.renderer.setAttribute(
                    ellipsis,
                    'cx',
                    (svgWidth / 2).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'cy',
                    (svgHeight / 2).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'rx',
                    (((svgWidth * 0.27) / 2) * magicNumberX).toString(),
                );
                this.renderer.setAttribute(
                    ellipsis,
                    'ry',
                    ((svgHeight / 2) * magicNumberY).toString(),
                );
                ellipsis.style.display = 'block';
            }, 50);
        } else {
            setTimeout(() => {
                const ellipsis = document.getElementById('ellipse');
                this.renderer.setAttribute(ellipsis, 'cx', (300).toString());
                this.renderer.setAttribute(ellipsis, 'cy', (300).toString());
                this.renderer.setAttribute(ellipsis, 'rx', (176).toString());
                this.renderer.setAttribute(ellipsis, 'ry', (265).toString());
            }, 50);
        }
    }
}
