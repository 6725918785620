import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VerifyOneToOne } from '../models/verify';
import { Builder } from 'builder-pattern';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OneToOneService {
    constructor(private http: HttpClient) {}

    public OneToOneVerify(
      request: any
    ): Observable<VerifyOneToOne> {

        return this.http
            .post(`${environment.api}/api/v1/onetoone/verify/`, request)
            .pipe(
                map((response: any) =>
                    Builder(VerifyOneToOne).score(response.Verifyinfo.score).build(),
                ),
            );
    }
}
