import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Strings } from '../classes/messages';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorDetailResponse, ErrorResponse } from '../classes/error-response';
import { Builder } from 'builder-pattern';
@Injectable({
    providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {
    private static getTypeErrorByStatus(status: number): string {
        if (status === 400) {
            return Strings.typeErrorForm;
        } else if (status === 401) {
            return Strings.typeErrorUnauthorized;
        } else if (status === 404) {
            return Strings.typeErrorNotFound;
        } else {
            return Strings.typeErrorUnknown;
        }
    }
    constructor(private authSrv: AuthenticationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.authSrv.user.pipe(
            first(),
            switchMap((user) => {
                let request = req;
                if (user) {
                    request = req.clone({
                        setHeaders: {
                            token: `Bearer ${user.token}`,
                            auth: `Bearer ${user.authToken}`,
                        },
                    });
                }

                return next.handle(request).pipe(
                    catchError((error: HttpErrorResponse) => {
                        console.log(error);
                        return throwError(
                            this.convertPayloadToErrorResponseModel(
                                error.error,
                                error.status,
                            ),
                        );
                    }),
                );
            }),
        );
    }

    private convertPayloadToErrorResponseModel(
        error: any,
        status: number,
    ): ErrorResponse {
        if (error && error.errors) {
            return Builder(ErrorResponse)
                .errors(
                    this.createErrorDetailResponseModelByErrors(
                        error.errors,
                        status,
                    ),
                )
                .build();
        } else {
            return Builder(ErrorResponse).build();
        }
    }

    private createErrorDetailResponseModelByErrors(
        error: any,
        status: number,
    ): Array<ErrorDetailResponse> {
        let errors: Array<ErrorDetailResponse> = [];

        if (Array.isArray(error)) {
            errors = error.map((err) => {
                if (typeof err === 'object') {
                    return Builder(ErrorDetailResponse)
                        .type(err.type)
                        .message(err.msg)
                        .build();
                } else if (typeof err === 'string') {
                    return Builder(ErrorDetailResponse)
                        .type(
                            AuthenticationInterceptorService.getTypeErrorByStatus(
                                status,
                            ),
                        )
                        .message(err)
                        .build();
                }
            });
        }

        return errors;
    }
}
