import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WindowService } from '../../services/window.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { Strings } from '../../classes/messages';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-login-phone-modal',
    templateUrl: './login-phone-modal.component.html',
    styleUrls: ['./login-phone-modal.component.scss'],
})
export class LoginPhoneModalComponent implements OnInit {
    windowRef: any;
    formSignin: FormGroup;
    constructor(
        private fb: FormBuilder,
        private modalCtrl: ModalController,
        private windowSrv: WindowService,
        private authSrv: AuthenticationService,
        private notifySrv: NotificationService,
        private modalSrv: ModalService,
    ) {}

    ngOnInit() {
        this.initForm();
    }

    async ionViewWillEnter() {
        await this.setup();
    }

    closeModal(): void {
        this.modalCtrl.dismiss().then();
    }

    async setup(): Promise<void> {
        this.windowRef = await this.windowSrv.windowRef;
        this.windowRef.recaptchaVerifier = await this.authSrv.getRecaptcha();
        await this.windowRef.recaptchaVerifier.render();
    }

    initForm(): void {
        this.formSignin = this.fb.group({
            phone: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(10),
                    Validators.minLength(10),
                ],
            ],
            prefix: ['+52', [Validators.required]],
        });
    }

    async signInWithPhone(): Promise<void> {
        try {
            const phoneNumber =
                this.formSignin.value.prefix + this.formSignin.value.phone;
            const appVerifier = this.windowRef.recaptchaVerifier;
            const res = await this.authSrv.singInWithPhoneNumber(
                phoneNumber,
                appVerifier,
            );
            if (res) {
                await this.modalSrv.openModalConfirmPhone(
                    res.verificationId,
                    phoneNumber,
                    appVerifier,
                );
            }
        } catch (e) {
            await this.notifySrv.showToastDanger(Strings.errorUknow);
        }
    }
}
