import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.staging) {
  Sentry.init({
    dsn: 'https://ac7ea84c70274de982136325a71a748a@o466306.ingest.sentry.io/5713126',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://demo.jaakrecog.com/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
