import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuardService implements CanActivate {
    pathPublicList: Array<string> = ['/login', '/signup'];
    constructor(
        private authSrv: AuthenticationService,
        private $router: Router,
    ) {}

    canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot,
    ): Observable<boolean> {
        const path = routerStateSnapshot.url;
        return this.authSrv.user.pipe(
            map((user: User) => {
                if (user) {
                    if (this.isPublicPath(path)) {
                        this.redirectToHome();
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (this.isPublicPath(path)) {
                        return true;
                    } else {
                        this.redirectToLogin();
                        return false;
                    }
                }
            }),
        );
    }

    private redirectToLogin(): void {
        this.$router.navigate(['/login']).then();
    }

    private redirectToSignup(): void {
        this.$router.navigate(['/signup']).then();
    }

    private redirectToHome(): void {
        this.$router.navigate(['/home']).then();
    }

    private redirectToCameraPermission(): void {
        this.$router.navigate(['/camera-permission']).then();
    }

    private redirectToIdCardCheck(): void {
        this.$router.navigate(['/ine-check']).then();
    }

    private isPublicPath(path: string): boolean {
        return !!this.pathPublicList.find((p) => {
            return path.startsWith(p);
        });
    }
}
