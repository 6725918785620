import { AddressData, DocumentImage } from './document';
export class ExtractDocument {
    id: string;
    status: boolean;
    message: string;
    documentType: number;
    documentData: ExtractDocumentData;
    documentMetadata: string;
    processingTime: number;
    image?: string;
    toObjectRequest(): any {
        return {
            eventId: this.id,
            document: this.image,
            documentType: this.documentType,
        };
    }
}

export class GeneralData {
    name: string;
    surname: string;
    motherSurname: string;
    address: AddressData;
    validUntil: string;
    birthDate: string;
    documentImage: DocumentImage;
    rfc?: string;
    curp?: string;
}

export interface SpecificData {
    label: string;
    value: string;
}

export interface ExtractDocumentData {
    generalData: GeneralData;
    mechanicalReadingZone: string;
    specificData: Array<SpecificData>;
}
