import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Document } from '../models/document';
import { ExtractDocument } from '../models/extract-document';
const { Storage } = Plugins;

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    public async setIneFrontImage(image: string): Promise<void> {
        return await Storage.set({
            key: 'ineFront',
            value: JSON.stringify(image),
        });
    }

    public async getIneFrontImage(): Promise<string> {
        const image = JSON.parse(
            await (
                await Storage.get({ key: 'ineFront' })
            ).value,
        );

        return image;
    }

    public async removeIneFront(): Promise<void> {
        return await Storage.remove({
            key: 'ineFront',
        });
    }

    public async setEventId(id: string): Promise<void> {
        return await Storage.set({
            key: 'eventId',
            value: JSON.stringify(id),
        });
    }
    public async getEventId(): Promise<string> {
        const eventId = JSON.parse(
            await (
                await Storage.get({ key: 'eventId' })
            ).value,
        );

        return eventId;
    }

    public async removeEventId(): Promise<void> {
        return await Storage.remove({
            key: 'eventId',
        });
    }

    public async setDocument(doc: ExtractDocument): Promise<void> {
        return await Storage.set({
            key: 'doc',
            value: JSON.stringify(doc),
        });
    }

    public async getDocument(): Promise<ExtractDocument> {
        const doc = JSON.parse(await (await Storage.get({ key: 'doc' })).value);

        return doc;
    }

    public async removeDocument(): Promise<void> {
        return await Storage.remove({
            key: 'doc',
        });
    }

    public async setIneBackCaptured(): Promise<void> {
        return await Storage.set({
            key: 'captureBack',
            value: JSON.stringify('CAPTURED'),
        });
    }

    public async getIneBackCaptured(): Promise<boolean> {
        const doc = JSON.parse(
            await (
                await Storage.get({ key: 'captureBack' })
            ).value,
        );

        return doc;
    }

    public async removeIneBackCaptured(): Promise<void> {
        return await Storage.remove({
            key: 'captureBack',
        });
    }

    public async setBestFrame(value: string): Promise<void> {
        return await Storage.set({
            key: 'bestFrame',
            value: JSON.stringify(value),
        });
    }

    public async getBestFrame(): Promise<boolean> {
        const doc = JSON.parse(
            await (
                await Storage.get({ key: 'bestFrame' })
            ).value,
        );

        return doc;
    }

    public async removeBestFrame(): Promise<void> {
        return await Storage.remove({
            key: 'bestFrame',
        });
    }

    public async removeStorage(): Promise<void> {
        await this.removeDocument();
        await this.removeEventId();
        await this.removeIneFront();
        await this.removeIneBackCaptured();
        await this.removeBestFrame();
    }
}
