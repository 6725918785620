import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToolsService } from '../../services/tools.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private modalSrv: ModalService,
    public authSrv: AuthenticationService,
    private toolsSrv: ToolsService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  closeModal(): void {
    this.modalCtrl.dismiss().then();
  }

  openLoginIpModal(): Promise<void> {
    return this.modalSrv.openModalLoginIP().then();
  }

  async logOut(): Promise<void> {
    await this.authSrv.logout();
    await this.closeModal();
    setTimeout(() => {
      this.navCtrl.navigateRoot(['login']).then();
    }, 100);
  }

}
