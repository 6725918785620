import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() kind: 'primary' | 'secondary' = 'primary';
  @Input() block: boolean;
  @Input() disabled: boolean;
}
