import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { ToolsService } from '../../services/tools.service';
import { StorageService } from '../../services/storage.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-step-one',
    templateUrl: './step-one.component.html',
    styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit {
    slideOpts = {
        allowTouchMove: false,
        pagination: false,
    };
    ineCaptured: any;
    @ViewChild('onBording') slides: IonSlides;
    @Output() valueResponse: EventEmitter<string> = new EventEmitter();
    constructor(
        private modalSrv: ModalService,
        private stepper: StepperService,
        private storageSrv: StorageService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.ineCaptured = await this.storageSrv.getDocument();
    }

    async goToStep2(): Promise<void> {
        this.stepper.updateStep('step2');
    }

    async captureIneFront(): Promise<void> {
        const dataIneFront = await this.modalSrv.openModalCaptureCardId(
            'front',
        );
        this.valueResponse.emit(dataIneFront);
    }
}
