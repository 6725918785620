import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal.service';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-on-boarding-id-card',
    templateUrl: './on-boarding-id-card.component.html',
    styleUrls: ['./on-boarding-id-card.component.scss'],
})
export class OnBoardingIdCardComponent implements OnInit {
    slideOpts = {
        allowTouchMove: false,
    };
    @ViewChild('onBording') slides: IonSlides;

    constructor(
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private toolsSrv: ToolsService,
    ) {}

    ngOnInit() {}

    async swipeBack(): Promise<void> {
        await this.toolsSrv.goRouteCardIdCheck();
        await this.modalCtrl.dismiss();
    }

    async continue(): Promise<void> {
        const index = await this.slides.getActiveIndex();
        console.log(index);
        if (index < 2) {
            await this.toolsSrv.goRouteCardIdCheck();
            await this.modalCtrl.dismiss();
        } else {
            await this.modalCtrl.dismiss();
        }
    }
}
