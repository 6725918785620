import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Builder } from 'builder-pattern';
import { Verify, BestFrame } from '../models/verify';
import { DocumentVerify, IneBackData } from '../models/document';
import {
    ExtractDocument,
    GeneralData,
    SpecificData,
} from '../models/extract-document';
import {
    DateObject,
    DocumentImage,
    IneFrontData,
    Position,
} from '../models/document';
import {
    AddressData,
    Document,
    DocumentData,
    GeoElectoralData,
} from '../models/document';
@Injectable({
    providedIn: 'root',
})
export class LivenessService {
    translateLabels = {
        electorKey: 'Clave Electoral',
        curp: 'CURP',
        rfc: 'RFC',
        registerYear: 'Año de Registro',
        emissionYear: 'Año de Emisión',
        'geoElectoralData.stateCode': 'Estado',
        'geoElectoralData.cityCode': 'Ciudad',
        'geoElectoralData.sectionCode': 'Sección',
        'geoElectoralData.localityCode': 'Localidad',
        identificationOCR: 'OCR',
        numIdentificationCredential: 'Num. Identificación',
    };
    constructor(private http: HttpClient) {}

    /**
     * It takes an array of video URLs and an optional event ID, and returns an observable of a Verify
     * object
     * @param video - Array<string> - An array of video URLs.
     * @param {string} [eventId] - The eventId returned from the `start` method.
     * @returns An Observable of type Verify
     */
    public verify(video: Array<string>, eventId?: string): Observable<Verify> {
        const data = eventId ? { eventId, videos: video } : { videos: video };

        return this.http
            .post(`${environment.api}/api/v1/liveness/verify/`, data)
            .pipe(
                map((response: any) =>
                    Builder(Verify)
                        .id(response.eventId)
                        .status(response.status)
                        .evaluation(response.evaluation)
                        .facesFound(response.faces_found)
                        .message(response.message)
                        .processTime(response.process_time)
                        .build(),
                ),
            );
    }

    /**
     * It takes a video and an eventId and returns a BestFrame object
     * @param video - Array<string> - An array of base64 encoded images.
     * @param {string} [eventId] - The eventId is a unique identifier for the liveness verification. It is
     * generated by the server and returned in the response.
     * @returns The best frame of the video.
     */
    public verifyBestFrame(
        video: Array<string>,
        eventId?: string,
    ): Observable<BestFrame> {
        return this.http
            .post(`${environment.api}/api/v1/liveness/verify/best-frame/`, {
                eventId,
                videos: video,
            })
            .pipe(
                map((response: any) =>
                    Builder(BestFrame)
                        .id(response.eventId)
                        .status(response.status)
                        .evaluation(response.evaluation)
                        .facesFound(response.faces_found)
                        .message(response.message)
                        .processTime(response.process_time)
                        .bestFrame(response.best_frame)
                        .threshold(response.threshold ? response.threshold : 90)
                        .build(),
                ),
            );
    }

    /**
     * It takes a document, converts it to a request object, sends it to the API, and returns a document
     * @param {Document} doc - Document - this is the document object that you want to extract.
     * @returns An Observable of type Document
     */
    public extractDocumentV1(doc: Document): Observable<Document> {
        const data = doc.toObjectRequest();

        return this.http
            .post(`${environment.api}/api/v1/document/extract/`, data)
            .pipe(
                map((response: any) => {
                    if (response) {
                        console.log(response);
                        return this.convertResponseToDocumentModel(response);
                    }
                }),
            );
    }

    /**
     * It takes a document, converts it to a request object, sends it to the API, and returns an observable
     * of the response
     * @param {ExtractDocument} doc - ExtractDocument - this is the model that we created earlier.
     * @returns An Observable of type ExtractDocument
     */
    public extractDocument(doc: ExtractDocument): Observable<ExtractDocument> {
        const data = doc.toObjectRequest();
        return this.http
            .post(`${environment.api}/api/v2/document/extract/`, data)
            .pipe(
                map((response: any) => {
                    if (response) {
                        console.log(response);
                        return this.convertResponseToExtractDocumentModel(
                            response,
                        );
                    }
                }),
            );
    }

    /**
     * It takes an ExtractDocument object and returns an Observable of DocumentVerify
     * @param {ExtractDocument} doc - ExtractDocument
     * @returns A DocumentVerify object
     */
    public verifyDocument(doc: ExtractDocument): Observable<DocumentVerify> {
        const data = doc.toObjectRequest();
        return this.http
            .post(`${environment.api}/api/v2/document/verify/`, data)
            .pipe(
                map((response: any) => {
                    if (response) {
                        return Builder(DocumentVerify)
                            .evaluation(response.evaluation)
                            .eventId(response.eventId)
                            .message(response.message)
                            .status(response.status)
                            .build();
                    }
                }),
            );
    }

    /**
     * It takes a response from the API and converts it into a Document object
     * @param {any} response - any
     * @returns The response is a Document object.
     */
    public convertResponseToDocumentModel(response: any): Document {
        console.log(response);
        return Builder(Document)
            .id(response.eventId)
            .status(response.status)
            .documentType(response.documentType)
            .documentData(
                response.documentData
                    ? Builder(DocumentData)
                          .ineFrontData(
                              Builder(IneFrontData)
                                  .name(
                                      response.documentData.ineFrontData?.name,
                                  )
                                  .surname(
                                      response.documentData.ineFrontData
                                          ?.surname,
                                  )
                                  .motherSurname(
                                      response.documentData.ineFrontData
                                          ?.motherSurname,
                                  )
                                  .address(
                                      Builder(AddressData)
                                          .street(
                                              response.documentData.ineFrontData
                                                  ?.address.street,
                                          )
                                          .neighborhood(
                                              response.documentData.ineFrontData
                                                  ?.address.neighborhood,
                                          )
                                          .zipCode(
                                              response.documentData.ineFrontData
                                                  ?.address.zipCode,
                                          )
                                          .city(
                                              response.documentData.ineFrontData
                                                  ?.address.city,
                                          )
                                          .state(
                                              response.documentData.ineFrontData
                                                  ?.address.state,
                                          )
                                          .build(),
                                  )
                                  .electorKey(
                                      response.documentData.ineFrontData
                                          ?.electorKey,
                                  )
                                  .curp(
                                      response.documentData.ineFrontData?.curp,
                                  )
                                  .rfc(response.documentData.ineFrontData?.rfc)
                                  .geoElectoralData(
                                      Builder(GeoElectoralData)
                                          .stateCode(
                                              response.documentData.ineFrontData
                                                  ?.geoElectoralData.stateCode,
                                          )
                                          .cityCode(
                                              response.documentData.ineFrontData
                                                  ?.geoElectoralData.cityCode,
                                          )
                                          .sectionCode(
                                              response.documentData.ineFrontData
                                                  ?.geoElectoralData
                                                  .sectionCode,
                                          )
                                          .localityCode(
                                              response.documentData.ineFrontData
                                                  ?.geoElectoralData
                                                  .localityCode,
                                          )
                                          .build(),
                                  )
                                  .registerYear(
                                      response.documentData.ineFrontData
                                          ?.registerYear,
                                  )
                                  .emissionYear(
                                      response.documentData.ineFrontData
                                          ?.emissionYear,
                                  )
                                  .validUntil(
                                      response.documentData.ineFrontData
                                          ?.validUntil,
                                  )
                                  .registerMonth(
                                      response.documentData.ineFrontData
                                          ?.registerMonth,
                                  )
                                  .birthDate(
                                      response.documentData.ineFrontData
                                          ?.birthDate,
                                  )
                                  .birthPlace(
                                      response.documentData.ineFrontData
                                          ?.birthPlace,
                                  )
                                  .gender(
                                      response.documentData?.ineFrontData
                                          ?.gender,
                                  )
                                  .documentImage(
                                      Builder(DocumentImage)
                                          .photo(
                                              response.documentData.ineFrontData
                                                  ?.documentImage?.photo,
                                          )
                                          .position(
                                              Builder(Position)
                                                  .top(
                                                      response.documentData
                                                          .ineFrontData
                                                          ?.documentImage
                                                          ?.position?.Top,
                                                  )
                                                  .bottom(
                                                      response.documentData
                                                          .ineFrontData
                                                          ?.documentImage
                                                          ?.position?.Bottom,
                                                  )
                                                  .left(
                                                      response.documentData
                                                          .ineFrontData
                                                          ?.documentImage
                                                          ?.position?.Left,
                                                  )
                                                  .right(
                                                      response.documentData
                                                          .ineFrontData
                                                          ?.documentImage
                                                          ?.position?.Right,
                                                  )
                                                  .build(),
                                          )
                                          .build(),
                                  )
                                  .build(),
                          )
                          .ineBackData(
                              Builder(IneBackData)
                                  .qrCodes(
                                      response.documentData.ineBackData
                                          ?.qrCodes,
                                  )
                                  .barCodes(
                                      response.documentData.ineBackData
                                          ?.barCodes,
                                  )
                                  .identificationOCR(
                                      response.documentData.ineBackData
                                          ?.identificationOCR,
                                  )
                                  .numIdentificationCredential(
                                      response.documentData.ineBackData
                                          ?.numIdentificationCredential,
                                  )
                                  .mechanicalReadingZone(
                                      response.documentData.ineBackData
                                          ?.mechanicalReadingZone,
                                  )
                                  .build(),
                          )
                          .build()
                    : null,
            )
            .documentMetadata(response.documentMetaData)
            .processTime(response.processingTime)
            .message(response.message ? response.message : null)
            .build();
    }

    /**
     * It converts the response from the API into a model that can be used by the application
     * @param {any} response - any
     * @returns The response is being returned as a ExtractDocument object.
     */
    public convertResponseToExtractDocumentModel(
        response: any,
    ): ExtractDocument {
        console.log(response);
        return Builder(ExtractDocument)
            .id(response.eventId)
            .status(response.status)
            .documentType(response.documentType)
            .documentData({
                generalData: Builder(GeneralData)
                    .address(
                        Builder(AddressData)
                            .street(
                                response.documentData.generalData?.address
                                    ?.street,
                            )
                            .neighborhood(
                                response.documentData.generalData?.address
                                    ?.neighborhood,
                            )
                            .zipCode(
                                response.documentData.generalData?.address
                                    ?.zipCode,
                            )
                            .city(
                                response.documentData.generalData?.address
                                    ?.city,
                            )
                            .state(
                                response.documentData.generalData?.address
                                    ?.state,
                            )
                            .build(),
                    )
                    .birthDate(response.documentData.generalData?.birthDate)
                    .motherSurname(
                        response.documentData.generalData?.motherSurname,
                    )
                    .name(response.documentData.generalData?.name)
                    .surname(response.documentData.generalData?.surname)
                    .validUntil(response.documentData.generalData?.validUntil)
                    .documentImage(
                        Builder(DocumentImage)
                            .photo(
                                response.documentData.generalData?.documentImage
                                    ?.photo,
                            )
                            .position(
                                Builder(Position)
                                    .top(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.Top,
                                    )
                                    .bottom(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.Bottom,
                                    )
                                    .left(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.Left,
                                    )
                                    .right(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.Right,
                                    )
                                    .build(),
                            )
                            .build(),
                    )
                    .build(),
                mechanicalReadingZone:
                    response.documentData.mechanicalReadingZone,
                specificData: response.documentData.specificData?.map(
                    (item) => {
                        const data: SpecificData = {
                            label: this.translateLabels[item.label]
                                ? this.translateLabels[item.label]
                                : item.label,
                            value: item.value,
                        };
                        return data;
                    },
                ),
            })
            .documentMetadata(response.documentMetaData)
            .processingTime(response.processingTime)
            .message(response.message ? response.message : null)
            .build();
    }
}
