import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-video-retry-modal',
    templateUrl: './video-retry-modal.component.html',
    styleUrls: ['./video-retry-modal.component.scss'],
})
export class VideoRetryModalComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }
}
