import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-request-location-modal',
    templateUrl: './request-location-modal.component.html',
    styleUrls: ['./request-location-modal.component.scss'],
})
export class RequestLocationModalComponent implements OnInit {
    lat: number;
    lng: number;
    constructor(private notifySrv: NotificationService) {}

    ngOnInit() {}

    async getLocation(): Promise<void> {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    console.log(position);
                }
            });
        } else {
            await this.notifySrv.showToastDanger(
                'El navegador no es compatible con este servicio',
            );
        }
    }
}
