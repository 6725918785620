import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Builder } from 'builder-pattern';
import firebase from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { Strings } from '../classes/messages';
import { LoadingController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { AuthUserService } from './auth-user.service';
import { ToolsService } from './tools.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    get user(): Observable<User> {
        return this.user$.asObservable();
    }
    constructor(
        private afAuth: AngularFireAuth,
        private authUser: AuthUserService,
        private loadingCtrl: LoadingController,
        private storageSrv: StorageService,
        private toolsSrv: ToolsService,
        private notifySrv: NotificationService,
    ) {
        this.listenUserSession();
    }

    private static getFirstAndLastName(fullname: string): {
        firstName: string;
        lastName: string;
    } {
        let firstName: string;
        let lastName: string;

        const nameList = fullname ? fullname.split(' ') : [];

        if (nameList.length === 0) {
            firstName = '';
            lastName = '';
        } else if (nameList.length === 1) {
            firstName = nameList[0];
            lastName = '';
        } else if (nameList.length === 2) {
            firstName = nameList[0];
            lastName = nameList[1];
        } else if (nameList.length === 3) {
            firstName = nameList[0];
            lastName = nameList.slice(1, nameList.length).join(' ');
        } else if (nameList.length === 4) {
            firstName = nameList.slice(0, 2).join(' ');
            lastName = nameList.slice(2, nameList.length).join(' ');
        } else {
            firstName = nameList.slice(0, nameList.length - 2).join(' ');
            lastName = nameList
                .slice(nameList.length - 2, nameList.length)
                .join(' ');
        }

        return {
            firstName,
            lastName,
        };
    }

    public async factoryLoadUserSession(): Promise<void> {
        const loadingRef = await this.loadingCtrl.create({
            cssClass: 'app-loading',
            message: Strings.pleaseAwaitMessage,
            showBackdrop: true,
            spinner: 'crescent',
            duration: 0,
        });
        try {
            await loadingRef.present();
            const userSession = await this.getUserSession()
                .pipe(first())
                .toPromise();
            await this.setUserSession(userSession);
            await loadingRef.dismiss();
        } catch (error) {
            this.logout();
            await this.notifySrv.showToastDanger(
                error.error ? error.error.message : 'Ha ocurrido un error',
            );
            setTimeout(() => {
                this.toolsSrv.goRouteLoginPage();
            }, 100);
            console.log(error);
            await loadingRef.dismiss();
        }
    }

    public async signInWithGoogle(): Promise<any> {
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        await this.afAuth.signInWithRedirect(
            new firebase.auth.GoogleAuthProvider(),
        );
    }

    public async sigInWithFacebook(): Promise<any> {
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const provider = new firebase.auth.FacebookAuthProvider();
        await this.afAuth.signInWithRedirect(provider);
    }

    public async signInWithEmailAndPassword(data: {
        email: string;
        password: string;
    }): Promise<any> {
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const user = await this.afAuth.signInWithEmailAndPassword(
            data.email,
            data.password,
        );

        this.setUserSession(user.user);
    }

    public async signUpWithEmail(data: {
        name: string;
        email: string;
        password: string;
    }): Promise<any> {
        const credential = await this.afAuth.createUserWithEmailAndPassword(
            data.email,
            data.password,
        );
        await credential.user.updateProfile({ displayName: data.name });
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        this.setUserSession(credential.user);
    }

    public async getRecaptcha(): Promise<firebase.auth.RecaptchaVerifier> {
        return await new firebase.auth.RecaptchaVerifier('recaptcha-container');
    }

    public async singInWithPhoneNumber(
        phoneNumber: string,
        recaptchaVerifier: any,
    ): Promise<any> {
        return await this.afAuth.signInWithPhoneNumber(
            phoneNumber,
            recaptchaVerifier,
        );
    }

    public async verifyCode(
        code: string,
        verificationId: string,
    ): Promise<any> {
        const credential = await firebase.auth.PhoneAuthProvider.credential(
            verificationId,
            code,
        );
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const user = await this.afAuth.signInAndRetrieveDataWithCredential(
            credential,
        );
        this.setUserSession(user.user);
    }

    private listenUserSession(): void {
        this.getUserSession().subscribe(this.setUserSession);
    }

    private getUserSession(): Observable<firebase.User> {
        return this.afAuth.user;
    }

    private setUserSession = async (user: firebase.User) => {
        if (user) {
            try {
                const { firstName, lastName } =
                    AuthenticationService.getFirstAndLastName(user.displayName);
                const token = await user.getIdToken();
                const authToken = await this.authUser
                    .getJaakrecogToken(token)
                    .toPromise();
                console.log(authToken);
                this.user$.next(
                    Builder(User)
                        .id(user.uid)
                        .token(token)
                        .fullName(user.displayName)
                        .firstName(firstName)
                        .lastName(lastName)
                        .picture(user.photoURL)
                        .email(user.email)
                        .emailVerified(user.emailVerified)
                        .authToken(authToken ? authToken : null)
                        .provider(
                            user.providerData.map(
                                (provider) => provider.providerId,
                            ),
                        )
                        .build(),
                );
            } catch (error) {
                console.log(error);
            }
        } else {
            this.user$.next(null);
        }
    };

    public async logout(): Promise<void> {
        await this.storageSrv.removeStorage();
        await this.afAuth.signOut();
    }
}

export function factoryUserSession(authSrv: AuthenticationService) {
    return async () => {
        await authSrv.factoryLoadUserSession();
    };
}
