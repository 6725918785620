import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingsModalComponent } from '../modals/settings-modal/settings-modal.component';
import { LoginIPModalComponent } from '../modals/login-ip-modal/login-ip-modal.component';
import { LoginEmailModalComponent } from '../modals/login-email-modal/login-email-modal.component';
import { LoginPhoneModalComponent } from '../modals/login-phone-modal/login-phone-modal.component';
import { ConfirmPhoneModalComponent } from '../modals/confirm-phone-modal/confirm-phone-modal.component';
import { OnBordingModalComponent } from '../modals/on-bording-modal/on-bording-modal.component';
import { VideoCaptureModalComponent } from '../modals/video-capture-modal/video-capture-modal.component';
import { Alert } from '../interfaces/alert';
import { AlertModalComponent } from '../modals/alert-modal/alert-modal.component';
import { LoadingModalComponent } from '../modals/loading-modal/loading-modal.component';
import { VideoRetryModalComponent } from '../modals/video-retry-modal/video-retry-modal.component';
import { OnBoardingIdCardComponent } from '../modals/on-boarding-id-card/on-boarding-id-card.component';
import { CardIdCaptureModalComponent } from '../modals/card-id-capture-modal/card-id-capture-modal.component';
import { OnBoardingIdCardBackComponent } from '../modals/on-boarding-id-card-back/on-boarding-id-card-back.component';
import { ValidationModalComponent } from '../modals/validation-modal/validation-modal.component';
import { ValidateDataModalComponent } from '../modals/validate-data-modal/validate-data-modal.component';
import { Document } from '../models/document';
import { CaptureVideoModalComponent } from '../modals/capture-video-modal/capture-video-modal.component';
import { VideoCaptureOptions } from '../interfaces/capture-options';
import { RequestLocationModalComponent } from '../modals/request-location-modal/request-location-modal.component';
import { VideoCallComponent } from '../modals/video-call/video-call.component';
import { ExtractDocumentData } from '../models/extract-document';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(public modalCtrl: ModalController) {}

    public async openModalSettings(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: SettingsModalComponent,
            cssClass: 'small',
        });
        return await modal.present();
    }

    public async openModalLoginIP(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoginIPModalComponent,
            cssClass: 'small bg-primary',
        });

        return await modal.present();
    }

    public async openModalLoginEmail(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoginEmailModalComponent,
            cssClass: 'small bg-primary',
            id: 'loginEmail',
        });

        return await modal.present();
    }

    public async openModalLoginPhone(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoginPhoneModalComponent,
            cssClass: 'small bg-primary',
            id: 'loginPhone',
        });

        return await modal.present();
    }

    public async openModalConfirmPhone(
        verificationId: string,
        phone: string,
        verifier: any,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ConfirmPhoneModalComponent,
            cssClass: 'small bg-primary',
            componentProps: {
                verificationId,
                phone,
                verifier,
            },
            backdropDismiss: false,
            id: 'verifyCode',
        });

        return await modal.present();
    }

    public async openModalOnBording(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: OnBordingModalComponent,
            cssClass: 'medium bg-light',
            backdropDismiss: false,
        });

        return await modal.present();
    }

    public async openModalVideoCapture(stream: MediaStream): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: VideoCaptureModalComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: { stream },
            id: 'video-capture',
        });

        return await modal.present();
    }

    public async openModalVideoCall(data: {
        meetingId: string;
        userId: string;
        peerId: string;
    }): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: VideoCallComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: {
                meetingId: data.meetingId,
                userId: data.userId,
                peerId: data.peerId,
            },
            id: 'video-call',
        });

        return await modal.present();
    }

    public async openModalCaptureVideo(
        stream: MediaStream,
        options: VideoCaptureOptions = null,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: CaptureVideoModalComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: { stream, options },
            id: 'video-capture',
        });

        return await modal.present();
    }

    public async openModalAlert(alert: Alert): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AlertModalComponent,
            cssClass: 'small',
            backdropDismiss: false,
            componentProps: { alert },
        });

        return await modal.present();
    }

    public async openModalRequestLocation(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: RequestLocationModalComponent,
            cssClass: 'small',
            backdropDismiss: false,
            id: 'location-request',
        });

        return await modal.present();
    }

    public async closeModalRequestLocation(): Promise<void> {
        await this.modalCtrl.dismiss(null, null, 'location-request');
    }

    public async openModalLoading(message?: string): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoadingModalComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: { message },
            id: 'loading',
        });

        return await modal.present();
    }

    public async openModalVideoError(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: VideoRetryModalComponent,
            cssClass: 'large',
            backdropDismiss: false,
        });

        return await modal.present();
    }

    public async openModalOnBoardingIdCrd(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: OnBoardingIdCardComponent,
            cssClass: 'medium bg-light',
            backdropDismiss: false,
        });

        return await modal.present();
    }

    public async openModalOnBoardingIdCardBack(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: OnBoardingIdCardBackComponent,
            cssClass: 'medium bg-light',
            backdropDismiss: false,
        });

        return await modal.present();
    }
    public async openModalCaptureCardId(
        type?: 'front' | 'back',
        documentData?: ExtractDocumentData,
    ): Promise<any> {
        const modal = await this.modalCtrl.create({
            component: CardIdCaptureModalComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: {
                type,
                documentData,
            },
            id: 'card-check',
        });

        await modal.present();
        const data = await (await modal.onWillDismiss()).data;
        return data;
    }

    public async openModalValidation(status, data): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ValidationModalComponent,
            cssClass: 'medium',
            backdropDismiss: false,
            componentProps: {
                status,
                data,
            },
            id: 'validation',
        });

        return await modal.present();
    }

    public async openModalDataValidation(data: Document): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ValidateDataModalComponent,
            cssClass: 'big',
            backdropDismiss: false,
            componentProps: {
                data,
            },
            id: 'validation',
        });

        return await modal.present();
    }

    async closeLoadingModal(): Promise<void> {
        await this.modalCtrl.dismiss(null, null, 'loading');
    }
}
