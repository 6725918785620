import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';
import { ModalService } from '../../services/modal.service';
import { Strings } from 'src/app/classes/messages';

@Component({
    selector: 'app-validation-modal',
    templateUrl: './validation-modal.component.html',
    styleUrls: ['./validation-modal.component.scss'],
})
export class ValidationModalComponent implements OnInit {
    @Input() status: boolean;
    @Input() data: any;
    @ViewChild('laputa') cardIdImage: ElementRef;
    @ViewChild('laperra') bestFrameImage: ElementRef;
    constructor(
        private modalCtrl: ModalController,
        private toolsSrv: ToolsService,
        private modalSrv: ModalService,
    ) {}

    ngOnInit() {
        console.log(this.cardIdImage);
        console.log(this.bestFrameImage);
    }

    async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    async retry(): Promise<void> {
        await this.closeModal();
        await this.toolsSrv.goRouteCardIdCheck();
    }

    async goToSuccess(): Promise<void> {
        await this.closeModal();
        await this.modalSrv.openModalAlert({
            type: 'success-liveness',
            message: Strings.successLivenessConfirm,
            buttonType: 'continue',
            buttonFunction: async () => {
                await this.closeModal();
                await this.toolsSrv.goRouteCardIdCheck();
            },
        });
    }

   fixBinary(bin) {
        const length = bin.length;
        const buf = new ArrayBuffer(length);
        const arr = new Uint8Array(buf);
        for (let i = 0; i < length; i++) {
          arr[i] = bin.charCodeAt(i);
        }
        return buf;
      }
}
