import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Strings } from '../classes/messages';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController
  ) { }

  public async showToastDanger(
    message: string,
    duration = 5000,
): Promise<void> {
    const toast = await this.toastCtrl.create({
        message,
        color: 'danger',
        duration,
        position: 'top'
    });
    toast.present().then();
  }

  public async showLoading(
    message = Strings.pleaseAwaitMessage,
): Promise<void> {
    console.log(message);
    const loading = await this.loadingCtrl.create({
        message,
        mode: 'ios',
    });
    loading.present().then();
  }

  public async dismissLoading(): Promise<void> {
    await this.loadingCtrl.dismiss();
  }
}
