import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-login-ipmodal',
  templateUrl: './login-ip-modal.component.html',
  styleUrls: ['./login-ip-modal.component.scss'],
})
export class LoginIPModalComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  closeModal(): void {
    this.modalCtrl.dismiss().then();
  }

}
