import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import { Strings } from '../../classes/messages';
import { ModalController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-confirm-phone-modal',
    templateUrl: './confirm-phone-modal.component.html',
    styleUrls: ['./confirm-phone-modal.component.scss'],
})
export class ConfirmPhoneModalComponent implements OnInit {
    @Input() verificationId: string;
    @Input() phone: string;
    @Input() verifier: any;
    codeForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private authSrv: AuthenticationService,
        private modalSrv: ModalService,
        private notifySrv: NotificationService,
        private modalCtrl: ModalController,
        private toolsSrv: ToolsService,
        private storageSrv: StorageService,
    ) {}

    ngOnInit() {
        console.log(this.verificationId);
        this.initForm();
    }

    initForm(): void {
        this.codeForm = this.fb.group({
            digitOne: ['', [Validators.required]],
            digitTwo: ['', [Validators.required]],
            digitThree: ['', [Validators.required]],
            digitFour: ['', [Validators.required]],
            digitFive: ['', [Validators.required]],
            digitSix: ['', [Validators.required]],
        });
    }

    async keytab(event, position: number): Promise<void> {
        const nextInput = event.srcElement.nextElementSibling;
        if (position === 6 && this.codeForm.valid) {
            await this.verifyCode();
        }
        if (event.key === 'Backspace') {
            return;
        }
        nextInput.focus();
    }

    closeModal(): void {
        this.modalCtrl.dismiss();
    }

    async verifyCode(): Promise<void> {
        const formData = this.codeForm.value;
        const code =
            formData.digitOne +
            formData.digitTwo +
            formData.digitThree +
            formData.digitFour +
            formData.digitFive +
            formData.digitSix;
        try {
            await this.modalSrv.openModalLoading(Strings.pleaseAwaitMessage);
            await this.storageSrv.removeStorage();
            await this.authSrv.verifyCode(code, this.verificationId);
            await this.modalCtrl.dismiss(null, null, 'verifyCode');
            await this.modalCtrl.dismiss(null, null, 'loginPhone');
            await this.modalCtrl.dismiss(null, null, 'loading');
            this.toolsSrv.goRouteHome();
        } catch (e) {
            this.codeForm.reset();
            await this.modalCtrl.dismiss(null, null, 'loading');
            await this.notifySrv.showToastDanger(Strings.errorUknow);
        }
    }

    async resendCode(): Promise<void> {
        try {
            const res = await this.authSrv.singInWithPhoneNumber(
                this.phone,
                this.verifier,
            );

            if (res){
                this.verificationId = res.verificationId;
                await this.notifySrv.showToastDefault('El código se ha enviado satisfactoriamente');
            }
        }catch (e) {
            this.notifySrv.showToastDanger('El token expiro, vuelve a intentarlo');
            await this.closeModal();
        }
    }
}
