import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    constructor(public modalSrv: ModalService, public toolsSrv: ToolsService) {}

    ngOnInit() {}

    async openSettingsModal(): Promise<any> {
        await this.modalSrv.openModalSettings();
    }

    async goToDashboard(): Promise<void> {
        await this.toolsSrv.goRouteDashboard();
    }
}
