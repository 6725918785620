export class Strings {
    static successLiveness = 'Liveness Confirmado';
    static successMatchIdCard = 'Tu rostro 3D coincide con tu INE/IFE';
    static successFrontIdCard = 'La toma del frente INE/IFE fue un éxito. Continua con el reverso';
    static successBackIdCard = 'La toma del reverso INE/IFE fue un éxito';
    static errorFrontIdCard = 'Hubo un error en la toma del frente INE/IFE. Intentelo nuevamente';
    static errorBackIdCard = 'Hubo un error en la toma trasera del INE/IFE. Intentelo nuevamente';
    static errorFaceMatchIdCard = 'Tu rostro 3D no coincide con tu INE/IFE intentalo nuevamente';
    static errorServer = 'El servidor no se encuentra disponible por el momento';
    static errorNetwork = 'No hay conexión de red, intentar más tarde';
    static initServicesMessage = 'Inicializando servicios. Por favor espere...';
    static pleaseAwaitMessage = 'Cargando. Por favor espere...';
    static errorUknow = 'Ops! Ha ocurrido un error. Intentalo más tarde';
    static successLivenessConfirm = 'Tu identidad ha sido validada';
    static typeErrorForm = 'form';
    static typeErrorUnauthorized = 'unauthorized';
    static typeErrorNotFound = 'not_found';
    static typeErrorInvalidEmail = 'value_error.email';
    static typeErrorUnknown = 'unknown';
    static unknownError =
    'Lo sentimos, un error ha ocurrido, inténtalo mas tarde.';
}
