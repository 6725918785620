import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { ModalService } from '../../services/modal.service';
import { Strings } from '../../classes/messages';
import { NotificationService } from '../../services/notification.service';
import { StorageService } from '../../services/storage.service';
import { ToolsService } from '../../services/tools.service';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-login-email-modal',
    templateUrl: './login-email-modal.component.html',
    styleUrls: ['./login-email-modal.component.scss'],
})
export class LoginEmailModalComponent implements OnInit {
    loginForm: FormGroup;
    constructor(
        private modalCtrl: ModalController,
        private fb: FormBuilder,
        private authSrv: AuthenticationService,
        private modalSrv: ModalService,
        private notifySrv: NotificationService,
        private storageSrv: StorageService,
        private toolSrv: ToolsService,
    ) {}

    ngOnInit() {
        this.initForm();
    }

    initForm(): void {
        this.loginForm = this.fb.group(
            {
                email: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        Validators.pattern(
                            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                        ),
                    ],
                ],
                password: ['', [Validators.required, Validators.minLength(6)]],
            },
            { validators: this.validatePatternEmail },
        );
    }

    private validatePatternEmail(abstractControl: AbstractControl): void {
        const errorEmailPattern = abstractControl.get('email').errors;
        if (errorEmailPattern && errorEmailPattern.pattern) {
            abstractControl.get('email').setErrors({
                email: true,
            });
        }
    }

    closeModal(): void {
        this.modalCtrl.dismiss().then();
    }

    async signInWithEmail(): Promise<any> {
        try {
            await this.modalSrv.openModalLoading(Strings.pleaseAwaitMessage);
            await this.storageSrv.removeStorage();
            await this.authSrv.signInWithEmailAndPassword(this.loginForm.value);
            await this.modalCtrl.dismiss(null, null, 'loginEmail');
            await this.modalCtrl.dismiss(null, null, 'loading');
            await this.toolSrv.goRouteHome();
        } catch (e) {
            console.log(e);
            this.modalCtrl.dismiss();
            await this.notifySrv.showToastDanger(Strings.errorUknow);
        }
    }
}
