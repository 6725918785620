import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private toastCtrl: ToastController) {}

    public async showToastDanger(
        message: string,
        duration = 5000,
    ): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            color: 'danger',
            duration,
            position: 'top',
        });
        toast.present().then();
    }

    public async showToastDefault(
        message: string,
        duration = 5000,
    ): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            duration,
        });
        toast.present().then();
    }
}
