import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Sdp } from '../../interfaces/sdp';

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements OnInit {
    @Input() meetingId: string;
    @Input() userId: string;
    @Input() peerId: string;
    pcSender: any;
    pcReciever: any;

    constructor(private modalCtrl: ModalController, private http: HttpClient) {}

    ngOnInit() {
        this.pcSender = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        });

        this.pcSender.onicecandidate = (event) => {
            if (event.candidate === null) {
                console.log('Iniceee');
                this.http
                    .post<Sdp>(
                        'https://dev-webrct-server-635t26xtnq-uc.a.run.app:8080/webrtc/sdp/m/' +
                            this.meetingId +
                            '/c/' +
                            this.userId +
                            '/p/' +
                            this.peerId +
                            '/s/' +
                            true,
                        {
                            sdp: btoa(
                                JSON.stringify(this.pcSender.localDescription),
                            ),
                        },
                    )
                    .subscribe((response) => {
                        this.pcSender.setRemoteDescription(
                            new RTCSessionDescription(
                                JSON.parse(atob(response.Sdp)),
                            ),
                        );
                    });
            }
        };
    }

    startCall() {
        // sender part of the call
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((stream) => {
                var senderVideo: any = document.getElementById('senderVideo');
                senderVideo.srcObject = stream;
                var tracks = stream.getTracks();
                for (var i = 0; i < tracks.length; i++) {
                  console.log(stream.getTracks()[i]);
                    this.pcSender.addTrack(stream.getTracks()[i]);
                }
                this.pcSender
                    .createOffer()
                    .then((d) => this.pcSender.setLocalDescription(d));
            });
        // you can use event listner so that you inform he is connected!
        this.pcSender.addEventListener('connectionstatechange', (event) => {
            if (this.pcSender.connectionState === 'connected') {
                console.log('horray!');
            }
        });

        // receiver part of the call
        this.pcReciever.addTransceiver('video', { direction: 'recvonly' });

        this.pcReciever
            .createOffer()
            .then((d) => this.pcReciever.setLocalDescription(d));

        this.pcReciever.ontrack = function (event) {
            var receiverVideo: any = document.getElementById('receiverVideo');
            receiverVideo.srcObject = event.streams[0];
            receiverVideo.autoplay = true;
            receiverVideo.controls = true;
        };
    }

    async close(): Promise<void> {
        await this.modalCtrl.dismiss();
    }
}
