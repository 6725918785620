import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Builder } from 'builder-pattern';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BlackList, BlackListRequest } from '../models/black-list';

@Injectable({
    providedIn: 'root',
})
export class BlackListService {
    constructor(private http: HttpClient) {}

    public getBlackLists(data: BlackListRequest): Observable<Array<BlackList>> {
        return this.http.post(`${environment.api}/api/v1/blacklist/search/`, data).pipe(
            map((response: any) => {
                console.log(response);
                return response.data.map((responseData) =>
                    this.convertToBlackListModel(responseData),
                );
            }),
        );
    }

    convertToBlackListModel(data): BlackList {
        return Builder(BlackList)
            .name(data.blacklist)
            .notes(data.notes)
            .build();
    }
}
