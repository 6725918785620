import { Component, Input, OnInit } from '@angular/core';
import { Alert } from 'src/app/interfaces/alert';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
    @Input() alert: Alert;
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    close(): void {
        this.modalCtrl.dismiss().then();
    }
}
