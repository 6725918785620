import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthUserService {
    constructor(private http: HttpClient) {}

    public getJaakrecogToken(userToken: string): Observable<string> {
        console.log(userToken);
        const data = {};
        const headers = new HttpHeaders({token: `Bearer ${userToken}`});

        return this.http
            .post(`${environment.api}/api/v1/oauth/`, data, {headers})
            .pipe(map((response: any) => response.jwt));
    }
}
