import { Component, Input, OnInit } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { LivenessService } from '../../services/liveness.service';
import { first } from 'rxjs/operators';
import { Alert } from '../../interfaces/alert';
import { Strings } from '../../classes/messages';
import { ToolsService } from '../../services/tools.service';
import { StorageService } from '../../services/storage.service';
import { Builder } from 'builder-pattern';
import { Document } from '../../models/document';
import { StepperService } from 'src/app/services/stepper.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Buffer } from 'buffer';
import { NotificationService } from '../../services/notification.service';

import {
    ExtractDocument,
    ExtractDocumentData,
} from '../../models/extract-document';

@Component({
    selector: 'app-card-id-capture-modal',
    templateUrl: './card-id-capture-modal.component.html',
    styleUrls: ['./card-id-capture-modal.component.scss'],
})
export class CardIdCaptureModalComponent implements OnInit {
    image: string;
    newImage: string;
    steper = 'step1';
    @Input() type = 'front';
    @Input() documentData: ExtractDocumentData = null;
    message = 'Toma o sube un archivo del frente de tu identificación';
    extractDocument: Document;
    constructor(
        private imageCompress: NgxImageCompressService,
        private modalSrv: ModalService,
        private modalCtrl: ModalController,
        private stepperSrv: StepperService,
        private notificationSrv: NotificationService,
    ) {}

    ngOnInit() {
        console.log(this.type);
        this.message =
            this.type === 'back'
                ? 'Toma o sube un archivo del reverso de tu identificación'
                : 'Toma o sube un archivo del frente de tu identificación';
    }

    async uploadFile(): Promise<void> {
        const file = await this.imageCompress.uploadFile();
        const buffer = Buffer.from(
            file.image.substring(file.image.indexOf(',') + 1),
        );
        const fileSize = buffer.length / 1e6;
        if (fileSize > 2.5) {
            await this.notificationSrv.showToastDanger(
                'El tamaño del archivo debe ser menor a 2.5MB',
            );
        } else {
            this.image = await this.imageCompress.compressFile(
                file.image,
                file.orientation,
                90,
                100,
            );
        }
    }

    nextStep(): void {
        this.steper = 'step2';
    }

    backStep(): void {
        this.steper = 'step1';
    }

    async verifyCard(): Promise<any> {
        try {
            const docImage = this.newImage.split(',')[1];
            console.log(docImage);
            const newDoc = Builder(ExtractDocument)
                .image(docImage)
                .id(null)
                .build();
            this.modalCtrl.dismiss(newDoc);
        } catch (e) {
            console.error(e);
        }
    }

    createSuccessAlert(data?: Document): Alert {
        return {
            type: 'success',
            message:
                this.type === 'front'
                    ? Strings.successFrontIdCard
                    : Strings.successBackIdCard,
            buttonType: 'continue',
            buttonFunction: async () => {
                await this.modalCtrl.dismiss();
                if (this.type === 'front') {
                    this.stepperSrv.updateStep('step2');
                } else {
                    this.stepperSrv.updateStep('step3');
                }
            },
        };
    }

    createErrorAlert(errMsg: string = null, type: string = null): Alert {
        const buttonType = type === 'unauthorized' ? null : 'try';
        return {
            type: 'error',
            message: errMsg
                ? errMsg
                : this.type === 'front'
                ? Strings.errorFrontIdCard
                : Strings.errorBackIdCard,
            buttonType,
            buttonFunction: async () => {
                await this.modalCtrl.dismiss();
                if (this.type === 'back') {
                    await this.modalSrv.openModalCaptureCardId('back');
                } else {
                    await this.modalSrv.openModalCaptureCardId('front');
                }
            },
        };
    }

    imageCropped(event: ImageCroppedEvent) {
        this.newImage = event.base64;
    }

    closeModal() {
        return this.modalCtrl.dismiss().then();
    }
}
