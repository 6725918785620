export const environment = {
    production: false,
    staging: true,
    api: 'https://dev.api.jaakrecog.com',
    apiOneToOne: 'https://facade-1to1-http-635t26xtnq-uc.a.run.app',
    firebaseConfig: {
        apiKey: 'AIzaSyCB6LFIE89STEczcCP9JGIMw-uHPkmzjT4',
        authDomain: 'jaakrecog-1to1-similarity.firebaseapp.com',
        projectId: 'jaakrecog-1to1-similarity',
        storageBucket: 'jaakrecog-1to1-similarity.appspot.com',
        messagingSenderId: '19169445718',
        appId: '1:19169445718:web:ef05261dce9b978434b8f7',
        measurementId: 'G-SH7GVCW22L',
    },
};
