import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {
    IneBackData,
    GeoElectoralData,
    IneFrontData,
    Document,
} from '../../models/document';
import { ToolsService } from '../../services/tools.service';
import { ModalController } from '@ionic/angular';
import { StorageService } from '../../services/storage.service';
import { StepperService } from '../../services/stepper.service';
import { ExtractDocument, GeneralData } from '../../models/extract-document';

@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html',
    styleUrls: ['./step-three.component.scss'],
})
export class StepThreeComponent implements OnInit {
    dataForm: FormGroup;
    data: ExtractDocument;

    constructor(
        private fb: FormBuilder,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.data = await this.storageSrv.getDocument();
        this.initForm();
    }

    initForm(): void {
        const userData: GeneralData = this.data.documentData.generalData;
        console.log(userData);
        this.dataForm = this.fb.group({
            birthdate: [userData.birthDate, [Validators.required]],
            city: [userData.address.city, [Validators.required]],
            council: [userData.address.council],
            lastname: [userData.surname, [Validators.required]],
            motherSurname: [userData.motherSurname, [Validators.required]],
            name: [userData.name, [Validators.required]],
            neighborhood: [
                userData.address.neighborhood,
                [Validators.required],
            ],
            street: [userData.address.street, [Validators.required]],
            state: [userData.address.state, [Validators.required]],
            suburb: [userData.address.neighborhood, [Validators.required]],
            surname: [userData.motherSurname, [Validators.required]],
            validUntil: [userData.validUntil, [Validators.required]],
            zipCode: [userData.address.zipCode, [Validators.required]],
        });
    }

    goToStep4(): void {
        this.stepperSrv.updateStep('step4');
    }
    goToStep2(): void {
        this.stepperSrv.updateStep('step2');
    }
}
