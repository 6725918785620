import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { AppService } from '../../services/app.service';
import { Strings } from '../../classes/messages';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { LivenessService } from '../../services/liveness.service';
import { first } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { ModalController } from '@ionic/angular';
import { Alert } from '../../interfaces/alert';
import { ToolsService } from '../../services/tools.service';
import { element } from 'protractor';
import { StorageService } from '../../services/storage.service';
import { OneToOneService } from '../../services/one-to-one.service';
declare var faceapi: any;
declare var MediaRecorder: any;
@Component({
    selector: 'app-video-capture-modal',
    templateUrl: './video-capture-modal.component.html',
    styleUrls: ['./video-capture-modal.component.scss'],
})
export class VideoCaptureModalComponent implements OnInit {
    @Input() stream: MediaStream;
    @ViewChild('video') public video: ElementRef;
    @ViewChild('ellipse') public smEllipese: ElementRef;
    @ViewChild('ellipsebig') public xlEllipese: ElementRef;
    tracker = null;
    stepper = new BehaviorSubject<string>('step1');
    animationShow = false;
    recordedBlobs = [];
    mediaRecorder: any;
    getScreenSize(event?) {
        const scrWidth = window.innerWidth;
        return scrWidth;
    }
    constructor(
        private appSrv: AppService,
        private notifySrv: NotificationService,
        private livenessSrv: LivenessService,
        private modalSrv: ModalService,
        private modalCtrl: ModalController,
        private toolsSrv: ToolsService,
        private renderer: Renderer2,
        private storageSrv: StorageService,
        private oneToOneSrv: OneToOneService,
    ) {}

    @HostListener('window:resize', ['$event'])
    private onResize(event) {
        const windowWidth: number = event.target.innerWidth;
        if (this.stepper.value === 'step4' || this.stepper.value === 'step5') {
            this.resizeEllipsisBig(windowWidth);
        } else {
            this.resizeEllipsis(windowWidth);
        }
    }

    async ngOnInit(): Promise<void> {
        await this.setup();
        this.stepper.asObservable().subscribe(async (value) => {
            const windowSize = this.getScreenSize();
            if (value === 'step2') {
                this.resizeEllipsis(windowSize);
                setTimeout(async () => {
                    await this.step2();
                }, 500);
            }
            if (value === 'step3') {
                this.resizeEllipsis(windowSize);
                this.step3();
            }

            if (value === 'step4') {
                this.resizeEllipsisBig(windowSize);
                setTimeout(async () => {
                    await this.step4();
                }, 500);
            }
            if (value === 'step5') {
                this.resizeEllipsisBig(windowSize);
                this.step5();
            }
        });
    }

    async setup(): Promise<void> {
        await this.appSrv.showLoading(Strings.initServicesMessage);
        setTimeout(() => {
            this.video.nativeElement.srcObject = new MediaStream(this.stream);
            this.video.nativeElement.play();
        }, 300);
        await this.setupFaceApi();
        await this.appSrv.dismissLoading();
        this.resizeEllipsis(this.getScreenSize());
    }

    resizeEllipsis(windowWidth: number): void {
        setTimeout(() => {
            if (windowWidth <= 850) {
                this.renderer.setAttribute(
                    this.smEllipese.nativeElement,
                    'ry',
                    '178',
                );
                this.renderer.setAttribute(
                    this.smEllipese.nativeElement,
                    'rx',
                    '118',
                );
            } else if (windowWidth > 850) {
                this.renderer.setAttribute(
                    this.smEllipese.nativeElement,
                    'ry',
                    '212',
                );
                this.renderer.setAttribute(
                    this.smEllipese.nativeElement,
                    'rx',
                    '139',
                );
            }
        }, 1);
    }

    resizeEllipsisBig(windowWidth: number): void {
        setTimeout(() => {
            if (windowWidth <= 850) {
                this.renderer.setAttribute(
                    this.xlEllipese.nativeElement,
                    'ry',
                    '234',
                );
                this.renderer.setAttribute(
                    this.xlEllipese.nativeElement,
                    'rx',
                    '154',
                );
            } else if (windowWidth > 850) {
                this.renderer.setAttribute(
                    this.xlEllipese.nativeElement,
                    'ry',
                    '294',
                );
                this.renderer.setAttribute(
                    this.xlEllipese.nativeElement,
                    'rx',
                    '192',
                );
            }
        }, 1);
    }

    async nextStep(): Promise<void> {
        this.stepper.next('step2');
    }

    async step2(): Promise<void> {
        const tracker = await this.trackFaceRecog();
        if (tracker) {
            this.stepper.next('step3');
        } else {
            await this.notifySrv.showToastDefault(
                'No se detecto un rostro. Intenta otra vez.',
            );
            this.stepper.next('step1');
        }

        console.log('executer');
    }

    step3() {
        this.startRecording();
        this.animationShow = true;
        setTimeout(() => {
            this.mediaRecorder.pause();
            this.animationShow = false;
            this.stepper.next('step4');
        }, 3000);
    }

    async step4(): Promise<void> {
        const tracker = await this.trackFaceRecog();
        if (tracker) {
            this.stepper.next('step5');
        } else {
            await this.notifySrv.showToastDefault(
                'No se detecto un rostro. Acercate.',
            );
            this.step4();
        }
    }

    step5() {
        this.mediaRecorder.resume();
        this.animationShow = true;
        setTimeout(async () => {
            const blob = this.stopRecording();
            const video = await this.getBase64(blob);
            console.log(video);
            this.animationShow = false;
            await this.verifyVideo(video);
        }, 3000);
    }

    trackFaceRecog(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const video = document.getElementsByTagName('video')[0];
            const detection = await faceapi
                .detectSingleFace(video)
                .withFaceLandmarks();
            resolve(detection);
        });
    }

    async setupFaceApi(): Promise<void> {
        const path = '../../../assets/weights';
        await faceapi.nets.ssdMobilenetv1.loadFromUri(path);
        await faceapi.nets.faceRecognitionNet.loadFromUri(path);
        await faceapi.nets.faceLandmark68Net.loadFromUri(path);
    }

    startRecording(): void {
        let options = { mimeType: 'video/webm; codecs=opus,vp8' };
        if (MediaRecorder.isTypeSupported) {
            options = { mimeType: 'video/webm;codecs=vp9' };
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.error(`${options.mimeType} is not Supported`);
                options = { mimeType: 'video/webm;codecs=vp8' };
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.error(`${options.mimeType} is not Supported`);
                    options = { mimeType: 'video/webm' };
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        console.error(`${options.mimeType} is not Supported`);
                        options = { mimeType: '' };
                    }
                }
            }
        } else {
            options = { mimeType: '' };
        }
        this.recordedBlobs = [];
        try {
            this.mediaRecorder = new MediaRecorder(this.stream, options);
        } catch (e) {
            console.log(e);
        }
        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                this.recordedBlobs.push(event.data);
            }
        };
        this.mediaRecorder.start(100);
    }

    stopRecording(): Blob {
        this.mediaRecorder.stop();
        const blob = new Blob(this.recordedBlobs, { type: 'video/webm' });
        console.log(URL.createObjectURL(blob));
        return blob;
    }

    getBase64(file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve((reader.result as string).split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    }

    async verifyVideo(video: string): Promise<any> {
        try {
            await this.modalSrv.openModalLoading();
            const eventId = await this.storageSrv.getEventId();
            const res = await this.livenessSrv
                .verifyBestFrame([video], eventId)
                .pipe(first())
                .toPromise();
            console.log(res);
            await this.modalCtrl.dismiss(null, null, 'video-capture');
            await this.modalCtrl.dismiss(null, null, 'loading');
            if (res.status && res.evaluation > 90) {
                const alert: Alert = this.createSuccessAlert(res.bestFrame);
                await this.modalSrv.openModalAlert(alert);
            } else {
                await this.modalSrv.openModalVideoError();
            }
        } catch {
            await this.modalCtrl.dismiss(null, null, 'video-capture');
            await this.modalCtrl.dismiss(null, null, 'loading');
            const alert: Alert = this.createErrorAlert();
            await this.modalSrv.openModalAlert(alert);
        }
    }

    createSuccessAlert(bestFrameImage: string): Alert {
        return {
            type: 'success',
            message: Strings.successLiveness,
            buttonType: 'continue',
            buttonFunction: async () => {
                try {
                    await this.modalCtrl.dismiss();
                    await this.modalSrv.openModalLoading();
                    const data = {
                        image1: await this.storageSrv.getIneFrontImage(),
                        image2: bestFrameImage,
                        eventId: await this.storageSrv.getEventId(),
                    };
                    const res = await this.oneToOneSrv
                        .OneToOneVerify(data)
                        .pipe(first())
                        .toPromise();
                    await this.modalCtrl.dismiss(null, null, 'loading');
                    const images = {
                        image1 : `data:image/jpg;base64,${data.image1}`,
                        image2: `data:image/jpg;base64,${data.image2}`,
                    };
                    if (res.score > 96.1) {
                        await this.modalSrv.openModalValidation(true, images);
                    }else{
                        await this.modalSrv.openModalValidation(false, images);
                    }
                } catch (e) {
                    console.log(e);
                    await this.modalCtrl.dismiss(null, null, 'loading');
                    const alert: Alert = this.createErrorAlert();
                    await this.modalSrv.openModalAlert(alert);
                }
            },
        };
    }

    createErrorAlert(): Alert {
        return {
            type: 'server',
            message: Strings.errorServer,
            buttonType: 'try',
            buttonFunction: () => {
                this.modalCtrl.dismiss();
            },
        };
    }

    closeModal(): Promise<void> {
        return this.modalCtrl.dismiss().then();
    }
}
