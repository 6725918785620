export class Event{
    request:string;
    response:string;
    service:string;
    status:number;
    label?: string;
}


export class DashboardEventResponse{
    clientUuid:string;
    eventId:string;
    events:Array<Event>;
    message:string;
    status:boolean;

}


export class DashboardEventClient{
    clientUuid:string;
    eventId:string;
    eventsFound:Array<any>
    message:string;
    pagination:Array<any>;
    status:boolean;
}