export class User {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    picture: string;
    email: string;
    emailVerified: boolean;
    provider: Array<string>;
    token: string;
    authToken: string;
}
