import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ToolsService {
    constructor(private router: Router) {}

    public goRouteLoginPage(): void {
        this.router.navigate(['/login']).then();
    }

    public goRouteCameraPermission(): void {
        this.router.navigate(['/camera-permission']).then();
    }

    public async goRouteCardIdCheck(): Promise<void> {
        await this.router.navigate(['/ine-check']);
    }

    public async goRouteHome(): Promise<void> {
        await this.router.navigate(['/home']);
    }

    public async goRouteDashboard(): Promise<void> {
        await this.router.navigate(['/dashboard']);
    }
}
