import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import { StepperService } from '../../services/stepper.service';
import { CallSessionService } from '../../services/call-session.service';
import { StorageService } from '../../services/storage.service';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardEventResponse } from '../../models/event';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
    showLoading: boolean = true;
    eventId: string;
    results: DashboardEventResponse;
    constructor(
        private alertCtrl: AlertController,
        private callSessionSrv: CallSessionService,
        private dashboardSrv: DashboardService,
        private modalsSrv: ModalService,
        private notifySrv: NotificationService,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showLoading = true;
        await this.getEventIdFromStorage();
        await this.getResults();
        this.showLoading = false;
    }

    private async getEventIdFromStorage(): Promise<void> {
        this.eventId = await this.storageSrv.getEventId();
        console.log(this.eventId);
    }

    async getResults(): Promise<void> {
        this.results = await this.dashboardSrv
            .getEventById(this.eventId)
            .toPromise();
        console.log(this.results);
    }

    async requestInfoAlert(): Promise<void> {
        const alert = await this.alertCtrl.create({
            header: 'Video Llamada',
            message: 'Completa la información',
            inputs: [
                {
                    type: 'text',
                    name: 'meetingId',
                    placeholder: 'MeetingId',
                    label: 'MeetingId',
                },
                {
                    type: 'text',
                    name: 'userId',
                    placeholder: 'UserId',
                    label: 'UserId',
                },
                {
                    type: 'text',
                    name: 'peerId',
                    placeholder: 'PeerId',
                    label: 'PeerId',
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: 'Iniciar',
                    handler: async (data) => {
                        console.log(data);
                    },
                },
            ],
        });
        await alert.present();
    }
    goToStepBlackLists(): void {
        return this.stepperSrv.updateStep('stepList');
    }
    async openVideoCall(): Promise<void> {
        try {
            await this.modalsSrv.openModalLoading();
            const resp = await this.callSessionSrv
                .getJaakrecogToken()
                .toPromise();
            window.alert('Link del asistente: ' + resp.assistantUrl);
            await this.modalsSrv.closeLoadingModal();
            window.location.href = resp.clientUrl;
        } catch (error) {
            await this.modalsSrv.closeLoadingModal();
            console.log(error);
            await this.notifySrv.showToastDanger(
                'Ops ha ocurrido un error. Intentalo más tarde',
            );
        }
    }

    async endProcess(): Promise<void> {
        await this.storageSrv.removeStorage();
        window.location.reload();
    }
}
