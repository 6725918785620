export class Verify {
    id: string;
    status: string;
    evaluation: number;
    facesFound: Array<any>;
    message: string;
    processTime: number;
}

export class BestFrame {
    id: string;
    status: boolean;
    evaluation: number;
    facesFound: Array<string>;
    message: string;
    processTime: number;
    bestFrame: string;
    threshold: number;
}

export class VerifyOneToOne {
    score: number;
}
