import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { StepperService } from '../../services/stepper.service';
import { StorageService } from '../../services/storage.service';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-step-four',
    templateUrl: './step-four.component.html',
    styleUrls: ['./step-four.component.scss'],
})
export class StepFourComponent implements OnInit {
    slideOpts = {
        allowTouchMove: false,
        pagination: false,
    };
    @ViewChild('onBording') slides: IonSlides;
    capturedBack = false;
    constructor(
        private modalSrv: ModalService,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
        private appSrv: AppService,
    ) {}

    async ngOnInit(): Promise<void> {
        const getDoc = await this.storageSrv.getIneBackCaptured();
        console.log(getDoc);
        this.capturedBack = getDoc ? true : false;
    }

    goToStep3(): void {
        return this.stepperSrv.updateStep('step3');
    }

    async getPermissions(): Promise<void> {
      navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(async (stream) => {
              await this.modalSrv.openModalCaptureVideo(stream, {
                  trackFace: false,
              });
          })
          .catch(async (e) => {
              console.log(e);
              await this.appSrv.showToastDanger('Ops! Ocurrió un error');
          });
  }
}
