import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { OneToOneService } from '../../services/one-to-one.service';
import { first } from 'rxjs/operators';
import { Alert } from '../../interfaces/alert';
import { Strings } from '../../classes/messages';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { StepperService } from '../../services/stepper.service';
import { ToolsService } from '../../services/tools.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-step-five',
    templateUrl: './step-five.component.html',
    styleUrls: ['./step-five.component.scss'],
})
export class StepFiveComponent implements OnInit {
    @Output() identityResponse = new EventEmitter<{
        score: number;
        approve: boolean;
    }>();
    constructor(
        private authSrv: AuthenticationService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private oneToOneSrv: OneToOneService,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
        private toolsSrv: ToolsService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.verifyIdentity();
    }

    async verifyIdentity(): Promise<void> {
        try {
            const data = {
                image1: await this.storageSrv.getIneFrontImage(),
                image2: await this.storageSrv.getBestFrame(),
            };

            console.log(data.image1, data.image2);
            const res = await this.oneToOneSrv
                .OneToOneVerify(data)
                .pipe(first())
                .toPromise();
            const images = {
                image1: `data:image/jpg;base64,${data.image1}`,
                image2: `data:image/jpg;base64,${data.image2}`,
            };
            this.stepperSrv.updateStep('step6');
            if (res.score > 96.1) {
                this.identityResponse.emit({ score: res.score, approve: true });
            } else {
                this.identityResponse.emit({
                    score: res.score,
                    approve: false,
                });
            }
        } catch (e) {
            // this.identityResponse.emit({ score: 98, approve: true });
            console.log(e);
            if (e.error.type === 'unauthorized') {
                await this.authSrv.logout();
                setTimeout(() => {
                    this.toolsSrv.goRouteLoginPage();
                }, 150);
            }
            const alert: Alert = this.createErrorAlert(
                e.error.message,
                e.error.type,
            );
            await this.modalSrv.openModalAlert(alert);
        }
    }

    createErrorAlert(errMsg?: string, errType?: string): Alert {
        const buttonType = errType === 'unauthorized' ? null : 'try';
        return {
            type: errType === 'unauthorized' ? 'error' : 'server',
            message: Strings.errorServer,
            buttonType,
            buttonFunction: () => {
                this.modalCtrl.dismiss();
                this.stepperSrv.updateStep('step4');
            },
        };
    }
}
