import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-on-boarding-id-card-back',
  templateUrl: './on-boarding-id-card-back.component.html',
  styleUrls: ['./on-boarding-id-card-back.component.scss'],
})
export class OnBoardingIdCardBackComponent implements OnInit {
  slideOpts = {
    allowTouchMove: false,
};
@ViewChild('onBording') slides: IonSlides;

constructor(
  private modalCtrl: ModalController,
  private modalSrv: ModalService
) {}

ngOnInit() {}


async swipeBack(): Promise<void> {
  await this.modalCtrl.dismiss();
}

async continue(): Promise<void> {
    const index = await this.slides.getActiveIndex();
    console.log(index);
    if (index < 2) {
        await this.modalCtrl.dismiss();
        await this.modalSrv.openModalCaptureCardId('back');
    } else {
        await this.modalCtrl.dismiss();
    }
}
}
