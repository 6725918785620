export class BlackList {
    name: string;
    notes: Array<string>;
}

export interface BlackListRequest {
    name: string;
    surname: string;
    motherSurname?: string;
    rfc: string;
    curp: string;
}
