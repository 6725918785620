import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
    Input,
} from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { StepperService } from '../../services/stepper.service';
import { StorageService } from '../../services/storage.service';
import {
    GeneralData,
    ExtractDocumentData,
} from '../../models/extract-document';

@Component({
    selector: 'app-step-two',
    templateUrl: './step-two.component.html',
    styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent implements OnInit {
    slideOpts = {
        allowTouchMove: false,
        pagination: false,
    };
    @ViewChild('onBording') slides: IonSlides;
    capturedBack = false;
    @Output() valueResponse: EventEmitter<string> = new EventEmitter();
    @Input() documentData: ExtractDocumentData;
    constructor(
        private modalSrv: ModalService,
        private stepperSrv: StepperService,
        private storageSrv: StorageService,
    ) {}

    async ngOnInit(): Promise<void> {
        const getDoc = await this.storageSrv.getDocument();
        console.log(getDoc);
        this.capturedBack = getDoc ? true : false;
    }

    goToStep1(): void {
        return this.stepperSrv.updateStep('step1');
    }

    goToStep3(): void {
        return this.stepperSrv.updateStep('step3');
    }

    async captureIneBack(): Promise<void> {
        const data = await this.modalSrv.openModalCaptureCardId('back', this.documentData);
        console.log(data);
        this.valueResponse.emit(data);
    }
}
