import { Component, OnInit } from '@angular/core';
import { Document } from 'src/app/models/document';
import { StorageService } from '../../services/storage.service';
import { BlackList, BlackListRequest } from '../../models/black-list';
import { BlackListService } from '../../services/black-list.service';
import { StepperService } from '../../services/stepper.service';
import { ExtractDocument, SpecificData } from '../../models/extract-document';

@Component({
    selector: 'app-step-black-list',
    templateUrl: './step-black-list.component.html',
    styleUrls: ['./step-black-list.component.scss'],
})
export class StepBlackListComponent implements OnInit {
    showLoading = false;
    doc: ExtractDocument;
    profilePicture: string;
    lists: Array<BlackList>;
    rfc: string;
    curp: string;
    constructor(
        private storageSrv: StorageService,
        private blackListSrv: BlackListService,
        private stepperSrv: StepperService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showLoading = true;
        this.doc = await this.storageSrv.getDocument();
        this.profilePicture = `data:image/jpg;base64,${this.doc.documentData.generalData.documentImage.photo[0]}`;
        console.log(this.doc)
        await this.getBlackLists();
        this.showLoading = false;
    }

    async getBlackLists(): Promise<void> {
        const ineData = this.doc.documentData.generalData;
        this.rfc = this.doc.documentData.specificData.find((data) => data.label === 'rfc')?.value;
        this.curp = this.doc.documentData.specificData.find((data) => data.label === 'curp')?.value; 
        const reqBodoy: BlackListRequest = {
            surname: ineData.surname,
            motherSurname: ineData.motherSurname,
            name: ineData.name,
            rfc: this.rfc ? this.rfc : null ,
            curp: this.curp ? this.curp : null,
        };
        this.lists = await this.blackListSrv
            .getBlackLists(reqBodoy)
            .toPromise();
    }

    async finish(): Promise<void> {
        this.stepperSrv.updateStep('stepResults');
    }
    goToStepOneToOne(): void {
        return this.stepperSrv.updateStep('step6');
    }
}
