import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from './guards/authentication-guard.service';

const routes: Routes = [
    {
        path: 'login',
        canActivate: [AuthenticationGuardService],
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'signup',
        loadChildren: () =>
            import('./pages/signup/signup.module').then(
                (m) => m.SignupPageModule,
            ),
        canActivate: [AuthenticationGuardService],
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthenticationGuardService],
    },

    {
        path: 'dashboard',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then(
                (m) => m.DashboardPageModule,
            ),
        canActivate: [AuthenticationGuardService],
    },
    {
        path: 'assistant/:meetingId/:userId/:peerId',
        loadChildren: () =>
            import('./pages/assistant/assistant.module').then(
                (m) => m.AssistantPageModule,
            ),
    },
    {
        path: 'client/:meetingId/:userId/:peerId',
        loadChildren: () =>
            import('./pages/client/client.module').then(
                (m) => m.ClientPageModule,
            ),
    },
    {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
